
import { ConfigKey, getConfig } from '@/utils/config';
import { useSelector } from 'umi';
import { IModel, ModelAction, ModelDispatch, ModelEffect, ModelReducer, ModelState, ModelStateSelector } from './model';

export interface UserModelState extends ModelState {
  tid: number;
  token: string;
  userInfo: any;
  apps: any[];
  resources: any[];
  roles: any[];
  appRoles: any[];
  appResources: any[];
  canConfUi: boolean;
  confKey: string;
}

export interface IUserModel extends IModel<UserModelState> {
  effects: {
    loadStorage: ModelEffect<UserModelAction>;
    logout: ModelEffect<UserModelAction>;
  };

  reducers: {
    save: ModelReducer<UserModelState>
  };
}

type ActionKeys = keyof IUserModel["effects"] | keyof IUserModel["reducers"]

export type UserModelAction = ModelAction<ActionKeys, any>;

export type UserModelDispatch = ModelDispatch<UserModelAction, any>;

const selector: ModelStateSelector<UserModelState> = (state: any) => state.user;

export function useUserState(): UserModelState {
  return useSelector(selector);
}

const UserModel: IUserModel = {
  state: {
    tid: 0,
    token: '',
    userInfo: {},
    apps: [],
    resources: [],
    roles: [],
    appRoles: [],
    appResources: [],
    canConfUi: false,
    confKey: '',
  },
  effects: {
    *loadStorage(_, { put }) {
      const cachedTid = getConfig(ConfigKey.tidKey) || 0;
      const tokenString = getConfig(ConfigKey.userTokenKey) || '';
      const loadUserInfo = getConfig(ConfigKey.userInfoKey) || {};
      const state: UserModelState = {
        tid: cachedTid,
        token: tokenString,
        userInfo: {},
        apps: [],
        resources: [],
        roles: [],
        appResources: [],
        appRoles: [],
        canConfUi: false,
        confKey: '',
      };

      if (loadUserInfo && loadUserInfo.id) {
        state.tid = loadUserInfo.tid;
        state.userInfo = loadUserInfo;
        state.token = loadUserInfo.token || tokenString || '';
      }

      yield put<UserModelAction>({
        type: 'save',
        payload: state
      });

      return state;
    },

    *logout (_, { put }) {
      yield put<UserModelAction>({
        type: 'save',
        payload: {
          tid: 0,
          token: '',
          userInfo: {},
          apps: [],
          resources: [],
          roles: [],
          appRoles: [],
          appResources: [],
          canConfUi: false,
          confKey: '',
        }
      });
    }
  },
  reducers: {
    save(state, { payload }) {
      const t = {
        ...state,
        ...payload,
      };

      t.canConfUi = t.confKey && (t.roles?.findIndex((s: any) => s.roleCode.indexOf('Admin') > -1) > -1);

      return t;
    }
  }
};

export default UserModel;
