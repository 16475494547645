
import React, { Component } from 'react';

import svgSources from './svg/index';

export type LoadingType = "blank" | "balls" | "bars" | "bubbles" | "cubes" | "cylon" | "spin" | "spinningBubbles" | "spokes";

export type LoadingProps = {
    color? : string;
    height? : any;
    width? : any;
    delay?: number;
    type? : LoadingType;
    className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export interface LoadingState {
    delayed : boolean;
}

export default class Loading extends Component<LoadingProps, LoadingState> {

  static defaultProps = {
    color: '#fff',
    delay: 0,
    type: 'balls',
    height: 64,
    width: 64,
  };

  state = {
    delayed: false,
  };

  timeout?: number;

  constructor (props: LoadingProps) {
    super(props);
    this.state.delayed = (props.delay || 0) > 0;
  }

  componentDidMount() {
    const { delay } = this.props;
    const { delayed } = this.state;

    if (delayed) {
      this.timeout = setTimeout(() => {
        this.setState({
          delayed: false,
        });
      }, delay);
    }
  }

  componentWillUnmount() {
    const { timeout } = this;

    if (timeout) {
      clearTimeout(timeout);
    }
  }

  render() {
    const {
      color, delay, type, height, width, ...restProps
    } = this.props;

    const selectedType = this.state.delayed ? 'blank' : type;
    // @ts-expect-error
    const svg = svgSources[selectedType || 'spin'];
    const style = {
      fill: color,
      height: Number(height) || height,
      width: Number(width) || width,
    };


    return (
      <div
        style={style}
        dangerouslySetInnerHTML={{ __html: svg }}
        {...restProps}
      />
    );
  }
}
