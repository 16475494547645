
export default {
  // Common
  'OK': '操作成功',
  'SYS_ERROR': '系统错误',
  'BIZ_ERROR': '业务错误',
  'INVALID_ARGUMENTS': '参数错误',
  'UNAUTHORIZED': '无权操作',
  'SAVE_ERROR': '保存失败',
  'UPDATE_ERROR': '更新失败',
  'DELETE_ERROR': '删除失败',
  'INVALID_IDEMPOTENT': '数据重复提交, 请间隔1分钟再提交',
  'DATA_DUPLICATE_ERROR': '数据重复, 请检查数据',

  // App ERP
  'MM20000': '已入不能大于需入',
  'MM20010': '没有生成收料单',

  // App Main
  'AM_20000': '租户无效',
  'AM_30000': '文件上传失败',

  // BD
  'BD_20010': '父级无效',
  BD_20020: '表达式有误',
  BD_20030: '表达式有误',
  'BD_20040': '该物料分类或下级分类被物料使用，不允许删除',
  BD_20050: '生成编码失败',
  BD_20060: '起始数不能小于当前数',
  BD_20070: '单位转换关系不存在',
  BD_20080: '颜色名称重复',

  // UO
  'UO_30000': '登录名或密码错误',
  'UO_30001': '账户已禁用',
  'UO_30100': '登录失效',
  'UO_30110': '第三方授权码错误',
  'UO_30200': '编码已存在',
  'UO_30210': '缺少父级',
  'UO_30230': '存在子级',
  'UO_30240': '缺少组织',
  'UO_30270': '缺少用户',
  'UO_30250': '请输入密码',

  // BPM
  BPM_20010: '流程格式有误',
  BPM_20020: '流程审核配置有误',
  BPM_20030: '没有找到运行中的任务',
  BPM_30000: '流程图有误',

  // MT
  MT_40010: '物料数据不存在',
  'MT_40020': '该物料分类或下级分类被物料使用，不允许删除',
  MT_40030: '物料名称重复',
  MT_40040: '物料编码重复',
  MT_40050: '无法删除，与现有物料信息关联',
  MT_40055: '无法删除，与现有物料信息关联',
  MT_40058: '无法删除，与现有物料信息关联',

  // PD
  PD_20010: '父级无效',
  PD_20020: '存在下级',
  PD_20050: '二次保存',

  // SK
  SK_20000: '数量无效',
  SK_20001: '仓库无效',
  SK_20002: '库件无效',
  SK_20003: '库位无效',
  SK_20004: '库件库存不存在',
  SK_20005: '可用库存不足',
  SK_20009: '占用不足',
  SK_20006: '库存变更失败',
  SK_20007: '变更类型无效',
  SK_20008: '占用失败',
  SK_20010: '仓库与库位有关联',
  SK_20011: '库位与库件有关联',
  SK_20012: '仓库编码唯一，不可重复创建',
  SK20022: '申请单号重复',
  SK20023: '已存在任务入库数量',
  SK20024: '任务入库数量不满足',

  // MM
  mm10001: '申请单号重复',
  mm10002: '二次保存',
  MM10003: '已经存在申请数量',
  MM10050: '可用库存不足',
};
