
export default {
  login: {
    loginName: {
      placeholder: '请输入登录名',
      valid: '请输入登录名'
    },
    password: {
      placeholder: '请输入登录密码',
      valid: '请输入登录密码'
    },
    loginText: '登录'
  }
}
