import React, { useState } from 'react'
import RootWrapper from './components/layout/RootWrapper';
import { MasterState } from '@C/typings';
// @ts-ignore
const buildInfo = BUILD_INFO;
console.table(buildInfo);

// if (!IsDev) {
//   Sentry.init({
//     dsn: "http://6b0197733dcc4c26af22f7953084bf76@47.96.228.87:9192/3",
//     release: DefVersion,
//     integrations: [new BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }

export const qiankun = {
  apps: [
    {
      name: 'mes',
      // @ts-expect-error
      entry: APP_MES_ENTRY
    },
    {
      name: 'erp',
      // @ts-expect-error
      entry: APP_ERP_ENTRY
    },
    {
      name: 'pad',
      // @ts-expect-error
      entry: APP_PAD_ENTRY
    }
  ]
}

export function useQiankunStateForSlave () {
  const [ masterState, setMasterState ] = useState<MasterState>({});
  const [ appState, setAppState ] = useState<any>({});

  return {
    masterState,
    setMasterState,
    appState,
    setAppState
  };
}

export function rootContainer (children: any) {
  return (
    <RootWrapper>
      { children }
    </RootWrapper>
  );
}
