
export default {
  // Common
  'OK': '操作成功',
  'SYS_ERROR': '系统错误',
  'BIZ_ERROR': '业务错误',
  'INVALID_ARGUMENTS': '参数错误',

  // App Main
  'AM_20000': '租户无效',

  // BD
  'BD_20010': '父级无效',

  // UO
  'UO_30000': '登录名或密码错误',
  'UO_30001': '账户已禁用',
  'UO_30100': '登录失效',
  'UO_30110': '第三方授权码错误',
  'UO_30200': '编码已存在',
  'UO_30250': '请输入密码'
};
