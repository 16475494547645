
export default {
  'RowState.ENABLED': '启用',
  'RowState.DISABLED': '禁用',
  'DisplayState.DISABLED': '否',
  'DisplayState.ENABLED': '是',
  'ResourceType.FUMCTION': '功能',
  'ResourceType.PAGE': '页面',
  'ResourceType.MENU': '菜单',
}
