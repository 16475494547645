
export default {
  loading: '加载中...',
  title: '比升智能管理平台',
  button: {
    add: '新增',
    edit: '编辑',
    delete: '删除',
    save: '保存',
    addResource: '添加资源',
    addUser: '添加人员',
    search: '查询',
    return:'返回'
  },
  modal: {
    title: {
      edit: '编辑',
      confirm: '确认',
      addUser: '添加人员',
      error: '错误'
    },
    content: {
      selAppRole: '请先选择应用及角色'
    },
    confirmDelete: {
      title: '确认删除',
      content: '确定需要删除吗？',
      okText: '确定',
      cancelText: '取消',
    }
  },
  table: {
    row: {
      action: '操作',
      edit: '编辑',
      delete: '删除',
      deleteConfirm: '确认删除数据？',
      resetPWConfirm: '确认重置密码？',
      resetPW: '重置密码',
      add: '添加',
      added: '已添加',
      remove: '移除',
      position: '库位',
      finish: '完成'
    }
  },
  tab: {
    roleToUser: '角色绑人',
    userToRole: '人绑角色',
    roleAuthorize: '角色授权'
  },
  select: {
    placeholder: {
      selClient: '选择应用'
    }
  },
  tabPane: {
    tab: {
      waitChoice: '待选',
      alreadyChoice: '已选',
    }
  },
};
