
export default {
  appUserModel: {
    userCode: '人员编号',
    userName: '人员名称',
    phone: '手机号码',
    email: '邮箱地址',
    stationName: '岗位名称',
    orgName: '组织名称',
    levelName: '职级名称',
    rowState: '状态',
    password: '密码',
    salt: '加密salt'
  }
};
