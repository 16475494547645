
import { Spin } from 'antd';
import React from 'react';
import { useIntl } from 'umi';
import Loading from '../Loading';

const PageLoading: React.FC<{}> = ({}) => {

  const { formatMessage } = useIntl();

  return (
    <div style={{ textAlign: 'center', marginTop: '120px' }}>
      <Spin
        indicator={
          <Loading
            type="spin"
            color="#1890ff"
            width={64}
            height={64}
          />
        }
        tip={formatMessage({ id: 'ui.loading' })}
      />
    </div>
  );
}

export default PageLoading;
