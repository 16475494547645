
export default {
  'RowState.DISABLED': '禁用',
  'RowState.ENABLED': '启用',
  'DisplayState.DISABLED': '否',
  'DisplayState.ENABLED': '是',
  'ResourceType.FUMCTION': '功能',
  'ResourceType.PAGE': '页面',
  'ResourceType.MENU': '菜单',
  'BpmTaskAuditType.MULTI_ADD': '会签',
  'BpmTaskAuditType.MULTI_OR': '或签',
  'AuditStatus.STARTREVIEW': '审核中',
  'AuditStatus.ENDREVIEW': '完成',
  'AuditStatus.OVERREVIEW': '结束',

  CodeBizType: {
    MM_ORDER_NO: '采购订单',
    SK_TASK_APPLY_NO: '库存申请单',
    MM_RECEIVE_NO: '采购收料单',
    MM_REJECT_NO: '采购退料单',
    SK_INVENTORY_NO: '出入库单',
    MM_LIST_NO: '采购清单',
    MM_SALE_NO: '客户订单',
    MM_MAKE_NO: '生产订单',
    MT_COLOR_NO: '物料颜色',
    MT_STYLE_COLOR_NO: '款式颜色',
    MT_MATERIAL_NO: '物料信息',
    IE_SUPPLEMENT_NO: '退料申请单',
    IE_RETREAT_SUPPLEMENT_NO: '补料申请单',
    IE_DEFECT_NO: '缺陷管理',
    DISPATCH_WORK_NO: '派工单号',
    MT_MATERIAL_SIZE: '物料尺码',
    CHECK_RECORD_CODE: '品检单号',
    ENTRUST_APPLY_CODE: '委外单号',
    MM_INVOICE_CODE: '采购订单发票号',
    MM_SALE_INVOICE_CODE: '销售订单发票号',
    MM_PROCESS_CODE: '材料加工单号',
    STOCK_INVENTORY_CODE: '盘点编码',
    STYLE_SECTION_TECHNICS: '部件工序库',
    PD_STAGE_CODE: '工序编码',
    DISPATCH_WORK_APPLY: '领料单编码',
    SUPPLIERS_CODE: '供应商编码',
    CUSTOMERS_CODE: '客户编码',
  },

  DateFormat: {
    YYYYMMDD: 'YYYYMMDD(20201001)',
    YYMMDD: 'YYMMDD(201001)',
    YYYYMM: 'YYYYMM(202010)',
    YYMM: 'YYMM(2010)',
    MMDD: 'MMDD(1001)'
  },

  PrintPaperSizeType: {
    A3: 'A3',
    A4: 'A4',
    A5: 'A5'
  },

  GenderType: {
    MALE: '男',
    FEMALE: '女'
  },

  ExamineState: {
    ENABLED: '已审核',
    DISABLED: '未审核',
  },

  DistributionState: {
    GROUP_EXAMINE: '组长已审',
    CLASS_EXAMINE: '科长已审',
    COMPLETE: '待审核',
    IE_CHECKED: 'IE已审',
  },

  // 盘点类型
  InventoryTypeState: {
    UNCHANGED: '不变',
    INVENTORY_SURPLUS: '盘盈',
    INVENTORY_LOSS: '盘亏',
  },

}
