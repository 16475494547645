
export default {
  search: {
    placeholder: {
      selectDept: '搜索部门',
    }
  },
  button: {
    addDept: '添加部门',
    delDept: '删除部门',
    updateDept: '修改部门',
    addUser: '添加人员',
  },
  message: {
    nodesCannotDel: '存在子节点不可以删除！',
    nodesDeptCannotDel: '请选择节点上的部门进行删除！',
    nodesDeptSelectUpdata: '请选择节点上的部门进行修改！',
    notSelectPeerDept: '不可以选择同级部门为上级部门！',
  },
  user: {
    enable: '启用人员',
    disable: '禁用人员',
    remove: '移除人员',
  }
}
